<template>
  <div>
    <div class="content">
      <div class="top_bg">
        <div class="title_css">
          <span>{{ $store.state.title }}-{{ $store.state.roleDesc }}</span>
          <span>
            第{{ $store.state.periods ? $store.state.periods : "*" }}期
          </span>
        </div>
      </div>
      <main>
        <van-form
          @submit="onSubmit"
          :show-error="false"
          :scroll-to-error="true"
        >
          <div class="store_information">
            <div class="title">门店信息</div>
            <slot
              :slotProps="{
                form,
                area,
                showArea,
                areaList,
                onFinish,
                storeType,
                storeTypeValue,
                storeTypeData,
                storeTypeonConfirm,
              }"
              name="store"
            >
              <van-field
                name="virtual_number"
                label="培训报名编号"
                placeholder="请输入"
                v-model="form.virtual_number"
                :rules="[{ required: true, message: '请输入培训报名编号' }]"
              />
              <van-field
                readonly
                name="camp_type"
                label="训练营类型"
                :value="campTypeSelectedValue.name"
                placeholder="请点击选择"
                @click="campTypeShow = true"
                :rules="[
                  { required: true, message: '请选择训练营类型' },
                  {
                    validator: campTypeValidator,
                    message: '训练营类型错误',
                    trigger: 'onChange',
                  },
                ]"
              />
              <van-popup v-model="campTypeShow" position="bottom">
                <van-picker
                  show-toolbar
                  value-key="name"
                  :columns="campTypeData"
                  @cancel="campTypeShow = false"
                  @confirm="
                    (value) => {
                      campTypeSelectedValue = value;
                      campTypeShow = false;
                    }
                  "
                />
              </van-popup>
              <van-field
                readonly
                name="area"
                v-model="area"
                label="书店地址"
                @click="showArea = true"
                placeholder="点击选择省市区"
                :rules="[{ required: true, message: '请选择省市区' }]"
              />
              <van-popup v-model="showArea" position="bottom">
                <van-cascader
                  title="请选择所在地区"
                  :options="areaList"
                  active-color="#4ea259"
                  :field-names="{
                    text: 'name',
                    value: 'district_id',
                    children: 'children',
                  }"
                  @close="showArea = false"
                  @finish="onFinish"
                />
              </van-popup>
              <van-field
                readonly
                name="store_type"
                label="店型"
                v-model="storeTypeValue"
                placeholder="请点击选择"
                @click="storeType = true"
                :rules="[{ required: true, message: '请选择店型' }]"
              />
              <van-popup v-model="storeType" position="bottom">
                <van-picker
                  show-toolbar
                  value-key="value"
                  :columns="storeTypeData"
                  @cancel="storeType = false"
                  @confirm="storeTypeonConfirm"
                />
              </van-popup>
              <template v-if="['4'].includes(campType)">
                <van-field
                  readonly
                  name="manager_role"
                  label="你的角色"
                  v-model="roleTypeValue"
                  placeholder="请点击选择"
                  @click="roleTypePickerShow = true"
                  :rules="[{ required: true, message: '请选择角色' }]"
                />
                <van-popup v-model="roleTypePickerShow" position="bottom">
                  <van-picker
                    show-toolbar
                    value-key="value"
                    :columns="roleTypeData"
                    @cancel="roleTypePickerShow = false"
                    @confirm="roleTypeonConfirm"
                  />
                </van-popup>
              </template>
              <div
                v-if="!$store.state.title.includes('店长')"
                class="van-field__label marginTop"
              >
                <span>店长信息</span>
              </div>
              <van-cell-group
                v-if="!$store.state.title.includes('店长')"
                class="noBorder"
                style="display: flex"
              >
                <van-field
                  name="manager_name"
                  class="noBorder"
                  placeholder="店长姓名"
                  v-model="form.manager_name"
                  :rules="[{ required: true, message: '请输入店长姓名' }]"
                />
                <van-field
                  name="manager_mobile"
                  type="tel"
                  placeholder="店长手机号"
                  v-model="form.manager_mobile"
                  :rules="[
                    {
                      required: true,
                      validator: verifyPhoneNumber,
                      message: '请输入正确手机号',
                    },
                  ]"
                />
              </van-cell-group>
              <van-field
                readonly
                name="region"
                placeholder="请选择"
                label="所在大区"
                v-model="regionValue"
                @click="regionPickerShow = true"
                :rules="[{ required: true, message: '请选择所在大区' }]"
              />
              <van-popup v-model="regionPickerShow" position="bottom">
                <van-picker
                  show-toolbar
                  value-key="value"
                  :columns="regionData"
                  @confirm="regionOnConfirm"
                  @cancel="regionPickerShow = false"
                />
              </van-popup>
            </slot>
          </div>
          <div class="store_information noBorder">
            <div class="title">我的信息</div>
            <van-field
              name="name"
              label="姓名"
              v-model="form.name"
              :rules="[{ required: true, message: '请输入你的姓名' }]"
              placeholder="请输入"
            />
            <van-field
              name="mobile"
              type="tel"
              label="手机号"
              placeholder="请输入"
              v-model="form.mobile"
              :rules="[
                { required: true, message: '请输入你的手机号' },
                { validator: verifyPhoneNumber, message: '请输入正确手机号' },
              ]"
            />
            <van-field
              name="identity_number"
              label="身份证号"
              placeholder="请输入身份证号"
              v-model="form.identity_number"
              :rules="[
                { required: true, message: '请输入身份证号' },
                { validator: idNumberValidator, message: '请输入正确身份证号' },
              ]"
              @blur="idNumberComplete"
            />
            <van-field
              name="sex"
              disabled
              label="性别"
              v-model="sexValue"
              placeholder="请输入身份证号"
              :rules="[{ required: true, message: '请输入身份证号' }]"
            />
            <!-- <van-popup v-model="showSex" position="bottom">
              <van-picker
                show-toolbar
                value-key="value"
                :columns="sexData"
                @confirm="sexOnConfirm"
                @cancel="showSex = false"
              />
            </van-popup> -->
            <van-field
              name="age"
              disabled
              label="年龄"
              v-model="form.age"
              placeholder="请输入身份证号"
              :rules="[{ required: true, message: '请输入身份证号' }]"
            />
            <van-field
              name="entry_time"
              readonly
              label="入职日期"
              placeholder="请选择"
              v-model="form.entry_time"
              :rules="[{ required: true, message: '请选择入职日期' }]"
              @click="showDatePicker = true"
            />
            <van-popup v-model="showDatePicker" position="bottom">
              <van-datetime-picker
                type="date"
                v-model="currentDate"
                @confirm="datePickerOnConfirm"
                @cancel="showDatePicker = false"
              />
            </van-popup>
            <van-field
              name="qualifications"
              readonly
              label="学历"
              v-model="xueli"
              placeholder="请选择"
              @click="showXueli = true"
              :rules="[{ required: true, message: '请选择学历' }]"
            />
            <van-popup v-model="showXueli" position="bottom">
              <van-picker
                show-toolbar
                value-key="value"
                @confirm="xueliOnConfirm"
                @cancel="showXueli = false"
                :columns="recordOfFormalSchooling"
              />
            </van-popup>
            <!-- 培训状态-start -->
            <template v-if="['1', '2', '3'].includes(campType)">
              <van-field
                name="train_status"
                readonly
                label="培训状态"
                v-model="trainingStatusValue"
                @click="trainingStatus = true"
                :rules="[{ required: true, message: '请选择培训状态' }]"
                placeholder="请选择"
              />
              <van-popup v-model="trainingStatus" position="bottom">
                <van-picker
                  show-toolbar
                  value-key="value"
                  :columns="trainingStatusData"
                  @confirm="trainingStatusOnConfirm"
                  @cancel="trainingStatus = false"
                />
              </van-popup>
            </template>
            <!-- 培训状态-end -->
            <!-- 全职培训-start -->
            <template v-if="['1', '4', '5', '6'].includes(campType)">
              <van-field
                name="full_time"
                readonly
                label="是否全职培训"
                v-model="full_timeValue"
                @click="full_time = true"
                :rules="[{ required: true, message: '请选择是否全职培训' }]"
                placeholder="请选择"
              />
              <van-popup v-model="full_time" position="bottom">
                <van-picker
                  show-toolbar
                  value-key="value"
                  :columns="full_timeData"
                  @confirm="showFull_timeOnConfirm"
                  @cancel="full_time = false"
                />
              </van-popup>
            </template>
            <!-- 全职培训-end -->
            <van-field
              name="trade"
              label="前行业"
              placeholder="请输入"
              v-model="form.trade"
              :rules="[{ required: true, message: '请输入前行业' }]"
            />
            <van-field
              name="experience"
              readonly
              placeholder="请选择"
              label="是否有书房交付经验"
              v-model="experienceValue"
              @click="showexperience = true"
              :rules="[{ required: true, message: '请选择是否有书房交付经验' }]"
            />
            <van-popup v-model="showexperience" position="bottom">
              <van-picker
                show-toolbar
                value-key="value"
                :columns="experience"
                @confirm="showexperienceOnConfirm"
                @cancel="showexperience = false"
              />
            </van-popup>
            <van-field
              name="age_stage"
              readonly
              placeholder="请选择"
              label="所带年龄段"
              v-model="carryAgeValue"
              @click="carryAgePickerShow = true"
              v-if="['5'].includes(campType)"
              :rules="[{ required: true, message: '请选择所带年龄段' }]"
            />
            <van-popup v-model="carryAgePickerShow" position="bottom">
              <!-- <van-picker
                show-toolbar
                value-key="value"
                :columns="carryAgeData"
                @confirm="carrAgeOnConfirm"
                @cancel="carryAgePickerShow = false"
              /> -->
              <van-checkbox-group v-model="carryAgeSelectedResult">
                <van-cell-group inset>
                  <van-cell
                    clickable
                    v-for="(item, index) in carryAgeData"
                    :key="index"
                    :title="item.value"
                    @click="carryAgeItemToggle(index)"
                  >
                    <template #right-icon>
                      <van-checkbox :name="item" ref="carryAgeItems" />
                    </template>
                  </van-cell>
                </van-cell-group>
                <div
                  style="
                    margin: 16px;
                    background-color: green;
                    color: white;
                    padding: 10px;
                    text-align: center;
                    border-radius: 8px;
                  "
                  @click.stop="carryAgePickerShow = false"
                >
                  确定
                </div>
              </van-checkbox-group>
            </van-popup>
            <van-field
              name="disease"
              readonly
              placeholder="请选择"
              label="是否有基础疾病"
              v-model="diseaseValue"
              @click="showDisease = true"
              v-if="['4', '5', '6'].includes(campType)"
              :rules="[{ required: true, message: '请选择是否有基础疾病' }]"
            />
            <van-popup v-model="showDisease" position="bottom">
              <van-picker
                show-toolbar
                value-key="value"
                :columns="diseaseData"
                @confirm="showDiseaseOnConfirm"
                @cancel="showDisease = false"
              />
            </van-popup>
            <van-field
              name="disease_info"
              v-if="diseaseValue === '是'"
              v-model="form.disease_info"
              type="textarea"
              label="若有请说明疾病类型"
              placeholder="请输入"
              :rules="[{ required: true, message: '请输入疾病详情' }]"
            />
            <!-- <van-field
              name="yang_kang"
              readonly
              placeholder="请选择"
              v-model="yangKangValue"
              label="是否“阳康”满一个月"
              @click="showYangKang = true"
              v-if="['4', '5', '6'].includes(campType)"
              :rules="[{ required: true, message: '请选择是否“阳康”满一个月' }]"
            />
            <van-popup v-model="showYangKang" position="bottom">
              <van-picker
                show-toolbar
                value-key="value"
                :columns="yangKangData"
                @confirm="yangKangOnConfirm"
                @cancel="showYangKang = false"
              />
            </van-popup> -->
            <van-field
              name="diet"
              readonly
              v-model="dietValue"
              placeholder="请选择"
              label="饮食是否有忌口"
              @click="showDiet = true"
              v-if="['4', '5', '6'].includes(campType)"
              :rules="[{ required: true, message: '请选择饮食是否有忌口' }]"
            />
            <van-popup v-model="showDiet" position="bottom">
              <van-picker
                show-toolbar
                value-key="value"
                :columns="dietData"
                @confirm="dietOnConfirm"
                @cancel="showDiet = false"
              />
            </van-popup>
            <van-field
              name="diet_info"
              v-if="dietValue === '是'"
              v-model="form.diet_info"
              type="textarea"
              label="若有忌口请详细说明"
              placeholder="请输入"
              :rules="[{ required: true, message: '请输入忌口详细说明' }]"
            />
            <van-field
              name="job"
              readonly
              label="所属岗位"
              v-model="jobValue"
              placeholder="请选择"
              @click="showJob = true"
              :rules="[{ required: true, message: '请选择所属岗位' }]"
            />
            <van-popup v-model="showJob" position="bottom">
              <van-picker
                show-toolbar
                value-key="value"
                :columns="jobData"
                @confirm="jobOnConfirm"
                @cancel="showJob = false"
              />
            </van-popup>
            <slot name="contactPerson">
              <van-field
                name="counterpart"
                readonly
                :label="labelText"
                placeholder="请选择"
                v-model="contactPersonValue"
                @click="showContactPerson = true"
                :rules="[{ required: true, message: '请选择' }]"
              />
              <van-popup v-model="showContactPerson" position="bottom">
                <van-picker
                  show-toolbar
                  value-key="value"
                  :columns="contactPersonData"
                  @confirm="contactPersonOnConfirm"
                  @cancel="showContactPerson = false"
                />
              </van-popup>
            </slot>
            <van-field
              name="objective"
              v-if="['1', '2', '4', '5', '6'].includes(campType)"
              v-model="form.objective"
              type="textarea"
              label="参加本次培训的目标/想要提升的技能"
              placeholder="请输入"
              :rules="[
                {
                  required: true,
                  message: '请输入本次培训的目标/想要提升的技能',
                },
              ]"
            />
            <van-field
              name="objective2"
              v-if="['3'].includes(campType)"
              readonly
              type="textarea"
              label="参加本次培训的目标"
              v-model="form.objective"
              placeholder="请选择"
              @click="showTarget = true"
              :rules="[{ required: true, message: '请选择参加本次培训的目标' }]"
            />
            <van-popup v-model="showTarget" position="bottom">
              <van-picker
                show-toolbar
                value-key="code"
                :columns="[
                  { code: '增强转化能力(规划师)' },
                  { code: '加深对孩子的了解,便于后期阅读指导(指导师)' },
                  {
                    code: '增强转化能力,加深对孩子的了解,便于后期的阅读指导(指导师,承担转化业务)',
                  },
                ]"
                @confirm="targetOnConfirm"
                @cancel="showTarget = false"
              />
            </van-popup>
            <div v-if="['1', '3', '4', '5', '6'].includes(campType)">
              <van-cell
                title="培训报名通知"
                value="查看"
                is-link
                @click="informShow = true"
              />
              <van-popup
                v-model="informShow"
                closeable
                :style="{ width: '100%', height: '70%' }"
              >
                <img
                  src="https://image.yuedushufang.com/archive/resource/enroll_notice_new.png"
                  alt="#"
                  style="width: 100%; height: 100%; object-fit: cover"
                />
              </van-popup>
            </div>
          </div>
          <div style="margin: 16px">
            <van-button
              class="submitBtn"
              color="#4EA259"
              block
              type="info"
              native-type="submit"
            >
              下一步
            </van-button>
          </div>
        </van-form>
      </main>
    </div>

    <van-popup
      v-model="secrectPageShow"
      position="bottom"
      closeable
      :style="{ width: '100%', height: '80%' }"
    >
      <secrect-page
        :form="form"
        @close="secrectPageShow = false"
        @complete="signatureComplete"
      />
    </van-popup>
  </div>
</template>

<script>
import SecrectPage from "@/views/form/SecrectPage.vue";
import { getSelectApi, getSelectLinkageApi } from "@/api";

export default {
  name: "VueVantPublicForm",
  components: { SecrectPage },
  data() {
    return {
      secrectPageShow: false,
      form: {
        virtual_number: "",
        shop_type: null, // 店铺类型
        manager_name: "", // 店长姓名
        manager_mobile: "", // 店长手机号
        name: "", // 我的姓名
        mobile: "", // 我的手机号
        identity_number: "", // 身份证号
        sex: null, // 性别
        age: null, // 年龄
        entry_time: null, // 入职日期
        qualifications: null, // 学历
        full_time: null, // 是否全职
        trade: "", // 前行业
        experience: null, // 经验
        disease: null, // 疾病
        disease_info: "", // 疾病详情
        // yang_kang: null, // 阳康
        diet: null, // 忌口
        diet_info: "", // 忌口说明
        job: null, // 所属岗位
        counterpart: null, // 对接人
        objective: "", // 培训目标
        manager_role: "", // 你的角色
        age_stage: "", // 所带年龄段
        region: "", // 大区
        signature: "", // 签名图片
      },
      // 训练营类型 1-阅读素养训练营 2-阅读素养进阶营-主题一 3-阅读测评解析咨询训练营 4-新晋店长训练营 5-阅读指导师训练营 6-阅读规划师训练营
      campType: JSON.parse(decodeURI(this.$route.query.camp_type)).toString(), // 训练营类型
      campID: JSON.parse(decodeURI(this.$route.query.camp_id)).toString(), // 训练营ID
      role: this.$route.query.role
        ? JSON.parse(decodeURI(this.$route.query.role)).toString()
        : "", // 当前角色
      area: "",
      campTypeData: [
        { name: "阅读素养训练营", value: "1" },
        { name: "阅读素养进阶营-主题一", value: "2" },
        { name: "阅读测评解析咨询训练营", value: "3" },
        { name: "新晋店长训练营", value: "4" },
        { name: "阅读指导师训练营", value: "5" },
        { name: "阅读规划师训练营", value: "6" },
      ],
      campTypeShow: false, // 训练营类型显示
      campTypeSelectedValue: {}, // 训练营类型选中的值
      showDatePicker: false, // 入职日期
      password: "",
      province: null, // 省ID
      city: null, // 市ID
      district: null, // 区ID
      showArea: false,
      areaList: [],
      showSex: false, // 性别弹框
      sexData: [], // 性别数据
      sexValue: "", // 选中的性别
      storeTypeData: [], // 店型数据
      showPicker: false, // 省市区
      storeType: false, // 店型
      storeTypeValue: "", // 店型选中的值
      roleTypePickerShow: false, // 你的角色
      roleTypeValue: "", // 你的角色
      roleTypeData: [], // 你的角色
      currentDate: new Date(),
      showXueli: false, // 学历
      xueli: "", // 学历选中的值
      recordOfFormalSchooling: [], // 学历数据
      experience: [], // 经验数据
      experienceValue: "", // 选中经验的值
      showexperience: false, // 显示经验的弹框
      carryAgeValue: "", // 所带年龄段
      carryAgePickerShow: false, // 所带年龄段
      carryAgeData: [], // 所带年龄段
      carryAgeSelectedResult: [],
      diseaseValue: "", // 是否有疾病选中的值
      showDisease: false, // 显示疾病的弹框
      diseaseData: [], /// 是否有疾病数据
      yangKangValue: "", // 阳康的选中的值
      yangKangData: [], // 阳康数据
      showYangKang: false, // 显示阳康-弹框
      showDiet: false, // 忌口-弹框
      dietValue: "", // 忌口选中的值
      dietData: [], // 忌口数据
      showJob: false, // 所属岗位-弹框
      jobValue: "", // 所属岗位选中的值
      jobData: [], // 所属岗位数据
      contactPersonValue: "", // 选中的对接人
      showContactPerson: false, // 对接人-弹框
      contactPersonData: [], // 对接人数据
      full_time: false, // 是否全职-弹框
      full_timeData: [], // 是否全职-数据
      full_timeValue: "", // 是否全职-选中的值
      trainingStatusData: [], // 培训状态数据
      trainingStatusValue: "", // 培训状态选中的值
      trainingStatus: false, // 培训状态-弹框
      regionValue: "", // 大区
      regionPickerShow: false, // 大区
      regionData: [], // 大区
      showTarget: false, // 培训目标
      informShow: true, // 报名通知
    };
  },
  computed: {
    labelText() {
      let label = "";
      switch (this.campType) {
        case "4":
        case "5":
        case "6":
          label = "招商经理/运营督导/实训中心";
          break;
        default:
          switch (this.role) {
            case "1":
              label = "招商经理";
              break;
            case "2":
              label = "运营督导";
              break;
            case "3":
              label = "实训中心";
              break;
            case "4":
              label = "开业督导";
              break;
            default:
              label = "招商经理/运营督导/实训中心";
              break;
          }
          break;
      }
      return label;
    },
    signatureDesc() {
      return this.form.signature ? "已阅读并签名" : "";
    },
  },
  watch: {
    carryAgeSelectedResult(newValue, oldValue) {
      this.carryAgeValue = newValue.map((i) => i.value).join(",");
      this.form.age_stage = newValue.map((i) => i.code).join(",");
    },
  },
  created() {
    this.getSelect();
    this.getSelectLinkage();
  },
  methods: {
    campTypeValidator(val) {
      return this.campType === this.campTypeSelectedValue.value;
    },
    idNumberValidator(val) {
      return /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
        val
      );
    },
    /**
     * @title 获取select数据
     */
    getSelect() {
      const keys =
        "shop_type,qualifications,sex,experience,disease,yang_kang,diet,job,full_time,business_supervise,mentor,train_status,business,supervise,start_supervise,age_stage,manager_role,region";
      getSelectApi({
        keys: keys,
      })
        .then((res) => {
          this.storeTypeData = res.data.shop_type;
          this.sexData = res.data.sex;
          this.recordOfFormalSchooling = res.data.qualifications;
          this.experience = res.data.experience;
          this.diseaseData = res.data.disease;
          this.yangKangData = res.data.yang_kang;
          this.dietData = res.data.diet;
          this.jobData = res.data.job;
          this.full_timeData = res.data.full_time;
          this.roleTypeData = res.data.manager_role;
          this.carryAgeData = res.data.age_stage;
          this.regionData = res.data.region;
          this.trainingStatusData = res.data.train_status;
          if (
            this.campType === "4" ||
            this.campType === "5" ||
            this.campType === "6"
          ) {
            const arr = [
              ...res.data.business_supervise,
              ...res.data.start_supervise,
            ];
            const newArr = arr.filter(function (item, index) {
              if (item.value === "其他") {
                return arr.findIndex((item) => item.value === "其他") === index;
              } else {
                return true;
              }
            });
            this.contactPersonData = newArr;
          } else {
            switch (this.role) {
              case "1":
                this.contactPersonData = res.data.business;
                break;
              case "2":
                this.contactPersonData = res.data.supervise;
                break;
              case "3":
                this.contactPersonData = res.data.mentor;
                break;
              case "4":
                this.contactPersonData = res.data.start_supervise;
                break;
              default:
                this.contactPersonData = res.data.business_supervise;
                break;
            }
          }
        })
        .catch((err) => {
          this.$notify({ type: "warning", message: err.message });
        });
    },
    /**
     * @title 培训状态-弹框-点击确定
     */
    trainingStatusOnConfirm(value, index) {
      if (value) {
        this.trainingStatusValue = value.value;
        this.form.train_status = this.trainingStatusData[index].code;
        this.trainingStatus = false;
      }
    },
    /**
     * @title 是否全职培训-弹框-点击确定
     */
    showFull_timeOnConfirm(value, index) {
      if (value) {
        this.full_timeValue = value.value;
        this.form.full_time = this.full_timeData[index].code;
        this.full_time = false;
      }
    },
    /**
     * @title 招商经理/运营督导-点击确定
     */
    contactPersonOnConfirm(value, index) {
      if (value) {
        this.contactPersonValue = value.value;
        this.form.counterpart = this.contactPersonData[index].code;
        this.showContactPerson = false;
      }
    },
    /**
     * @title 所属岗位-点击确定
     */
    jobOnConfirm(value, index) {
      this.jobValue = value.value;
      this.form.job = this.jobData[index].code;
      this.showJob = false;
    },
    /**
     * @title 忌口-点击确定
     */
    dietOnConfirm(value, index) {
      this.dietValue = value.value;
      this.form.diet = this.dietData[index].code;
      this.showDiet = false;
    },
    /**
     * @title 阳康-点击确定
     */
    yangKangOnConfirm(value, index) {
      this.yangKangValue = value.value;
      this.form.yang_kang = this.yangKangData[index].code;
      this.showYangKang = false;
    },
    /**
     * @title 所带年龄段-点击确定
     */
    carrAgeOnConfirm(value, index) {
      this.carryAgeValue = value.value;
      this.form.age_stage = this.carryAgeData[index].code;
      this.carryAgePickerShow = false;
    },
    /**
     * @title 所在大区-点击确定
     */
    regionOnConfirm(value, index) {
      this.regionValue = value.value;
      this.form.region = this.regionData[index].code;
      this.regionPickerShow = false;
    },
    /**
     * @title 是否有疾病-点击确定
     */
    showDiseaseOnConfirm(value, index) {
      this.diseaseValue = value.value;
      this.form.disease = this.diseaseData[index].code;
      this.showDisease = false;
    },
    /**
     * @title 交付经验-点击确定
     */
    showexperienceOnConfirm(value, index) {
      this.experienceValue = value.value;
      this.form.experience = this.experience[index].code;
      this.showexperience = false;
    },
    idNumberComplete(value) {
      if (!this.idNumberValidator(this.form.identity_number)) {
        return;
      }
      const age = this.getAgeFrom(this.form.identity_number);
      this.form.age = age;
      const sex = this.getGenderFrom(this.form.identity_number);
      this.form.sex = sex;
      this.sexValue = this.sexData.find((i) => i.code === sex).value;
    },
    /**
     * @title 学历-点击确定
     */
    xueliOnConfirm(value, index) {
      this.xueli = value.value;
      this.form.qualifications = this.recordOfFormalSchooling[index].code;
      this.showXueli = false;
    },
    /**
     * @title 性别-点击确定
     */
    sexOnConfirm(value, index) {
      this.sexValue = value.value;
      this.form.sex = this.sexData[index].code;
      this.showSex = false;
    },
    /**
     * @title 获取选择框联动数据
     */
    getSelectLinkage() {
      getSelectLinkageApi({ keys: "city_tree" })
        .then((res) => {
          this.areaList = res.data.city_tree;
        })
        .catch((err) => {
          this.$notify({ type: "warning", message: err.message });
        });
    },
    /**
     * @title 格式化选中的日期
     */
    formatter(time) {
      const nowdata = time == null ? new Date() : new Date(time);
      const year = nowdata.getFullYear();
      const month = nowdata.getMonth() + 1;
      const date = nowdata.getDate();
      let nowtime = year + "-";
      if (month < 10) {
        nowtime += "0";
      }
      nowtime += month + "-";
      if (date < 10) {
        nowtime += "0";
      }
      nowtime += date;

      return nowtime;
    },
    /**
     * @title 入职日期-点击确定
     */
    datePickerOnConfirm(value) {
      this.form.entry_time = this.formatter(value);
      this.showDatePicker = false;
    },
    /**
     * @title 店型-点击确定
     */
    storeTypeonConfirm(value, index) {
      this.storeTypeValue = value.value;
      this.form.shop_type = this.storeTypeData[index].code;
      this.storeType = false;
    },
    /**
     * @title 你的角色-点击确定
     */
    roleTypeonConfirm(value, index) {
      this.roleTypeValue = value.value;
      this.form.manager_role = this.roleTypeData[index].code;
      this.roleTypePickerShow = false;
    },
    /**
     * @title 省市区-选择完成后
     */
    onFinish({ selectedOptions }) {
      let two = 1;
      if (selectedOptions.length !== 3) two = "";
      this.area = `${selectedOptions[0].name}/${selectedOptions[1].name}/${
        two ? selectedOptions[2].name : ""
      }`;
      this.form.province = selectedOptions[0].district_id; // 省ID
      this.form.city = selectedOptions[1].district_id; // 市ID
      this.form.district = two ? selectedOptions[2].district_id : 0; // 区ID
      this.showArea = false;
    },
    carryAgeItemToggle(index) {
      this.$refs.carryAgeItems[index].toggle();
    },
    targetOnConfirm(value) {
      this.form.objective = value.code;
      this.showTarget = false;
    },
    /**
     * @title 验证手机号
     */
    verifyPhoneNumber(val) {
      return /^1[3-9]\d{9}$/.test(val);
    },
    signatureComplete(imgPath) {
      this.secrectPageShow = false;
      this.form.signature = imgPath;
    },
    /**
     * @title 提交表单
     */
    onSubmit(values) {
      this.form.camp_id = this.campID;
      this.form.camp_type = this.campType + "";
      this.form.role = this.role;
      this.secrectPageShow = true;
      // createFormApi(this.form)
      //   .then(() => {
      //     this.$router.push({
      //       name: "Success",
      //       params: {
      //         camp_type: this.campType,
      //         camp_id: this.campID,
      //         role: this.role,
      //       },
      //     });
      //   })
      //   .catch((err) => {
      //     this.$notify({ type: "warning", message: err.message });
      //   });
    },
    /**
     * 按身份证号码获取性别
     * @idNumber 身份证号码
     * @return 男：1；女：2
     */
    getGenderFrom(idNumber) {
      if (idNumber) {
        let genderCode; // 性别代码
        if (idNumber.length === 18) {
          // 二代身份证号码长度为18位（第17位为性别代码）
          genderCode = idNumber.charAt(16);
        } else if (idNumber.length === 15) {
          // 一代身份证号码长度为15位（第15位为性别代码）
          genderCode = idNumber.charAt(14);
        }
        if (genderCode && !isNaN(genderCode)) {
          // 两代身份证号码的性别代码都为男奇女偶
          if (parseInt(genderCode) % 2 === 0) {
            return 2;
          }
          return 1;
        }
      }
      return 0;
    },
    getAgeFrom(identityCard) {
      const len = (identityCard + "").length;
      if (len === 0) {
        return 0;
      } else {
        if (len !== 15 && len !== 18) {
          // 身份证号码只能为15位或18位其它不合法
          return 0;
        }
      }
      let strBirthday = "";
      if (len === 18) {
        // 处理18位的身份证号码从号码中得到生日和性别代码
        strBirthday =
          identityCard.substr(6, 4) +
          "/" +
          identityCard.substr(10, 2) +
          "/" +
          identityCard.substr(12, 2);
      }
      if (len === 15) {
        strBirthday =
          "19" +
          identityCard.substr(6, 2) +
          "/" +
          identityCard.substr(8, 2) +
          "/" +
          identityCard.substr(10, 2);
      }
      // 时间字符串里，必须是“/”
      const birthDate = new Date(strBirthday);
      const nowDateTime = new Date();
      let age = nowDateTime.getFullYear() - birthDate.getFullYear();
      // 再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
      if (
        nowDateTime.getMonth() < birthDate.getMonth() ||
        (nowDateTime.getMonth() === birthDate.getMonth() &&
          nowDateTime.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return age;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-field__label {
  width: 200px;
}

.submitBtn {
  border-radius: 16px;
}

.content {
  height: 100vh;
  overflow: auto;
}

.top_bg {
  width: 100%;
  height: 250px;
  background-image: url("../assets/images/form_top_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .title_css {
    display: flex;
    align-items: center;
    padding-top: 40px;
    margin-left: 24px;
    font-size: 40px;
    font-weight: 600;
    color: #ffffff;

    span:last-child {
      text-align: center;
      display: inline-block;
      // width: 110px;
      height: 44px;
      line-height: 46px;
      background: #ffffff;
      border-radius: 8px;
      font-size: 24px;
      font-weight: 600;
      color: #4ea259;
      margin-left: 20px;
      padding: 0 6px;
    }
  }
}

main {
  .store_information {
    padding: 0px 24px;
    margin-top: 10px;
    border-bottom: 12px solid #f4f6fa;

    .title {
      font-size: 26px;
      color: #4ea259;
      height: 64px;
      font-weight: 500;
      line-height: 64px;
      vertical-align: middle;
      border-bottom: 2px solid #4ea259;
    }
  }
}

.marginTop {
  margin-top: 24px;
}

.noBorder {
  border: none !important;
}

.noBorder::after {
  border: none !important;
}

/deep/ .van-cell {
  padding: 24px 0px;

  &::after {
    left: 0px;
    right: 0px;
  }
}

/deep/ .van-field__label > span {
  position: relative;
  color: #080f19;
  font-size: 28px;

  &::after {
    margin-left: 2px;
    color: #e24b4b;
    font-size: 28px;
    content: "*";
  }
}
</style>
