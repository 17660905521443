import request from '@/utils/request'
import request2 from '@/utils/request2'

/**
 * @title 获取-公共-下拉框数据
 * @returns
 */
export function getSelectApi(parameter) {
  return request({
    url: '/common/select/list',
    method: 'get',
    params: parameter
  })
}

/**
 * @title 获取-公共-下拉框联动数据
 * @returns
 */
export function getSelectLinkageApi(parameter) {
  return request({
    url: '/common/select/linkage',
    method: 'get',
    params: parameter
  })
}

/**
 * @title 创建报名表
 * @returns
 */
export function createFormApi(parameter) {
  return request({
    url: '/home/enroll/create',
    method: 'post',
    data: parameter
  })
}

/**
 * @title 可报名校验
 * @returns
 */
export function verificationApi(parameter) {
  return request({
    url: '/home/enroll/check',
    method: 'get',
    params: parameter
  })
}

/**
 * @title 上传
 * @returns
 */
export function uploadApi(parameter) {
  return request({
    url: '/common/file/upload-sign',
    method: 'get',
    params: parameter
  })
}

/**
 * @title 签名
 * @returns
 */
export function signatureApi(parameter) {
  return request({
    url: '/home/enroll/signature',
    method: 'post',
    data: parameter
  })
}

/**
 * 活动报名下拉框数据
 */
export function getSelectionParameter(parameter) {
  return request2({
    url: '/stag-api/distribute/franchisee/activity/get-selection-parameter',
    method: 'get',
    params: parameter
  })
}

/**
 * 活动报名下拉框数据
 */
export function activityApply(parameter) {
  return request2({
    url: '/stag-api/distribute/franchisee/activity/activity-apply',
    method: 'post',
    data: parameter
  })
}

/**
 * 活动报名下拉框数据
 */
export function getBillPayPath(parameter) {
  return request({
    url: '/home/enroll/get-pay-url',
    method: 'get',
    params: parameter
  })
}

/**
 * @title 收费单学员列表
 * @returns
 */
export function billStudentListApi (enrollId) {
  return request({
    url: '/home/enroll/get-student-list',
    method: 'get',
    params: { enroll_id: enrollId }
  })
}
