<template>
  <div>
    <PublicForm ref="publicForm">
      <template v-if="$route.query.camp_type === 4" #store="{ slotProps }">
        <van-field
          v-model="slotProps.form.virtual_number"
          label="培训报名编号"
          placeholder="请输入"
          :rules="[{ required: true, message: '请输入培训报名编号' }]"
        />
        <van-field
          readonly
          v-model="slotProps.area"
          label="地区选择"
          @click="clickArea()"
          placeholder="点击选择省市区"
          :rules="[{ required: true, message: '请选择省市区' }]"
        />
        <van-popup v-model="slotProps.showArea" position="bottom">
          <van-cascader
            title="请选择所在地区"
            :options="slotProps.areaList"
            active-color="#4ea259"
            :field-names="{
              text: 'name',
              value: 'district_id',
              children: 'children',
            }"
            @close="showArea = false"
            @finish="slotProps.onFinish"
          />
        </van-popup>
        <van-field
          readonly
          label="店型"
          v-model="slotProps.storeTypeValue"
          placeholder="请点击选择"
          @click="clickStoreType()"
          :rules="[{ required: true, message: '请选择店型' }]"
        />
        <van-popup v-model="slotProps.storeType" position="bottom">
          <van-picker
            show-toolbar
            value-key="value"
            :columns="slotProps.storeTypeData"
            @confirm="slotProps.storeTypeonConfirm"
            @cancel="storeTypeCancel()"
          />
        </van-popup>
      </template>
    </PublicForm>
  </div>
</template>

<script>
import { getSelectApi } from "@/api";
import PublicForm from "@/components/PublicForm.vue";
export default {
  name: "VueVantHomeView",
  components: {
    PublicForm,
  },
  data() {
    return {
      type: 1,
      counterpart: null, // 表单中对接人字段
      contactPersonValue: "", // 招商经理-选中的值
      showContactPerson: false, // 招商经理-弹框
      contactPersonData: [], // 招商经理-数据
    };
  },

  created() {
    // this.getSelect()
  },

  methods: {
    /**
     * @title 省市区-弹框-点击确定
     */
    clickArea() {
      this.$refs.publicForm.showArea = true;
    },
    /**
     * @title 省市区-弹框-点击取消
     */
    areaCancel() {
      this.$refs.publicForm.showArea = false;
    },
    /**
     * @title 店铺类型-弹框-点击确定
     */
    clickStoreType() {
      this.$refs.publicForm.storeType = true;
    },
    /**
     * @title 店铺类型-弹框-点击取消
     */
    storeTypeCancel() {
      this.$refs.publicForm.storeType = false;
    },
    /**
     * @title 招商经理-弹框-点击确定
     */
    contactPersonOnConfirm(value, index) {
      this.contactPersonValue = value.value;
      this.counterpart = this.contactPersonData[index].code;
      this.showContactPerson = false;
    },
    /**
     * @title 获取select数据
     */
    getSelect() {
      getSelectApi({
        keys: "business",
      })
        .then((res) => {
          this.contactPersonData = res.data.business;
        })
        .catch((err) => {
          this.$notify({ type: "warning", message: err.msg });
        });
    },
  },
};
</script>

<style lang="less" scoped></style>
