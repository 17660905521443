<template>
  <div>
    <van-nav-bar title="保密协议" />
    <div class="content">
      <main>
        <p>
          乙方 <span class="highlight">{{ form.name }}</span
          >， 身份证号 <span class="highlight">{{ form.identity_number }}</span
          >，
          因参加甲方培训需要知晓并且熟悉甲方的知识产权、经营、业务和前景等甲方的商业秘密。针对乙方参与的岗位培训内容和接下来岗位的特殊性和重要性，为切实保护甲方的商业秘密及其他合法权益，根据国家有关法律法规，本着平等、自愿、公平、诚信的原则，双方经协商一致，就保密事宜达成下列条款，以兹共同遵守：
          <br />
          第一条：甲乙双方确认，乙方应承担保密义务的甲方商业秘密包括但不限于以下内容：
          <br />
          1、技术信息:“约读书房儿童书店”涉及到的产品资料、借阅系统、招幕会员系统、培训系统资料信息、商标权以及其他加盟技术支持。
          <br />
          2、经营信息：包括但不限于合作协议、教学课件、视频、经营数据、客户名单等。
          <br />
          第二条：乙方的保密义务
          <br />
          1、不得以其他手段非法获取与本次培训目的无关的商业秘密。
          <br />
          2、不得向第三人披露甲方的商业秘密。
          <br />
          3、不得允许（包括出租、复制、出借、转让等）或者协助第三人使用甲方的商业秘密。
          <br />
          4、为保护知识产权，培训现场乙方不得对课程、课件进行录音、录像、使用远程会议系统或其他形式的传播、拷贝；不得对课程、课件进行任何形式的转发（如微信、邮件等）、传播或下载至任何电脑或移动设备端（如手机、平板电脑等）；不对课程进行转换、修改、租赁、出借、再经销、转租或者创作内容的衍生作品；不得以任何形式、任何理由透露给其他第三方、公共平台或进行其他有损于甲方产品权益的行为，否则，甲方有权追究法律责任。
          <br />
          第三条：保密期限
          <br />
          甲乙双方确认，乙方的保密义务自乙方参加培训开始直到该商业秘密公开时止。
          <br />
          第四条：乙方承诺，其在甲方接受培训及合作儿童书店期间，非经甲方书面同意，不得有下列行为：
          <br />
          1、为与甲方经营同类产品、从事同类业务的有竞争关系的任何第三方企业服务或拥有利益，向第三方企业透漏甲方的运营模式和商业机密，包括课件以及其他任何宣传资料。
          <br />
          2、自行创办或者经营与甲方属同类产品、从事同类业务的企业，对甲方经营模式进行效仿。
          <br />
          3、为与甲方在产品、市场或服务方面有直接或间接竞争的企业或者机构工作或者在这种企业、机构担任任何职务，包括股东、合伙人、董事、监事、经理、职员、代理人、顾问等，或虽然不担任职务，但为前述单位提供指导、咨询、协助或拥有利益。
          <br />
          4、其他有损甲方利益的行为。
          <br />
          第五条：乙方同意，如上述行为涉及其近亲属，或借助他人名义，均视为乙方行为，由乙方承担违约赔偿责任。
          <br />
          第六条：乙方如违反本协议约定的保密义务，应向甲方支付违约金贰万元，同时承担乙方因违约行为而给甲方造成的一切损失，包括但不限于律师费、诉讼费、评估费等。
          <br />
          第七条：因履行本协议发生的争议，甲乙双方协商解决，协商不成，任何一方均可向甲方住所地人民法院起诉。
          <br />
          第八条：甲乙双方确认，在签署本协议前已仔细审阅过本协议的内容，并完全了解本协议各条款的法律含义，双方同意遵守履行。
          <br />
          第九条：本协议一式贰份，双方各持壹份，经双方签字或盖章后生效，具有同等法律效力。
          <br />
          <span style="color: red">
            第十条：为了保证培训效果，我承诺培训中做到以下几点：
            <br />
            1、本人确保，我已成为报名参训所属约读书房加盟门店的正式全职员工。
            <br />
            2、本人确保，积极参与培训学习，严格遵守培训节奏，每日按时完成并提交作业，如累计3次未按培训节奏完成作业，将视为自动放弃本期培训学习；
            <br />
            3、本人确保，安排好工作和生活，培训期间周一至周六须规划出充足时间（预计每天不少于4-5小时）投入培训学习，不影响培训进度和质量，确保拿到培训收获和成果；
          </span>
          <br />
          <br />
          以上保密内容及培训承诺本人皆已知晓并遵守。
        </p>

        <p class="sign-title">承诺人签字：</p>
        <div style="position: relative" @click="signShow = true">
          <vueSignature
            ref="signature"
            :sigOption="{
              penColor: 'rgb(0, 0, 0)',
              backgroundColor: 'rgb(205,208,213)',
            }"
            :w="'100%'"
            :h="'90px'"
            :disabled="true"
            :defaultUrl="signature"
          >
          </vueSignature>
          <p class="sign-tip" v-if="!signature">点击签字</p>
        </div>
        <p class="sign-title">承诺日期：{{ moment().format("YYYY-MM-DD") }}</p>

        <div style="padding-top: 12px">
          <van-button color="#4EA259" block @click="confirm"> 提交 </van-button>
        </div>
      </main>
    </div>

    <van-popup v-model="signShow" :style="{ height: '100%' }">
      <sign-view @close="signShow = false" @success="signSuccess" />
    </van-popup>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import SignView from "@/components/SignView";
import base64toFile from "@/utils/strtofile.js";
import { uploadApi, createFormApi } from "@/api";

export default {
  name: "SecrectPage",
  components: { SignView },
  props: {
    name: { type: String, default: "" },
    idNumber: { type: String, default: "" },
    form: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      signature: undefined,
      signShow: false,
    };
  },
  methods: {
    moment,
    signSuccess(img) {
      this.signShow = false;
      this.signature = img;
      this.$refs.signature.fromDataURL(this.signature);
    },
    confirm() {
      if (!this.signature) {
        this.$toast("请阅读并签署保密协议");
        return;
      }
      this.uploadFile();
    },
    /// 上传签名
    uploadFile() {
      const file = base64toFile(this.signature, "file"); // base64图片格式转文件流
      uploadApi({ type: "signature", suffix: "png" }).then((res) => {
        const formData = new FormData();
        formData.append("key", res.data.dir);
        formData.append("OSSAccessKeyId", res.data.OSSAccessKeyId);
        formData.append("policy", res.data.policy);
        formData.append("signature", res.data.signature);
        formData.append("success_action_status", "200"); // 上传成功返回的状态码，不设置则是204
        formData.append("Content-Type", "image/png");
        formData.append("file", file); // 一定在最后
        axios
          .post(res.data.host, formData)
          .then((data) => {
            this.submitAction(res.data.dir);
          })
          .catch((err) => {
            this.$toast(err);
          });
      });
    },
    submitAction(imgPath) {
      const form = this.form;
      form.signature = imgPath;
      createFormApi(form)
        .then(() => {
          this.$router.replace({
            name: "Success",
            params: {
              camp_type: form.camp_type,
              camp_id: form.camp_id,
              role: form.role,
            },
          });
        })
        .catch((err) => {
          this.$notify({ type: "warning", message: err.message });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  height: calc(80vh - 5rem);
  padding: 30px 30px;
  overflow: scroll;
}
.highlight {
  font-weight: 500;
  border-bottom: 1px solid black;
}
.sign-tip {
  width: 100%;
  height: 180px;
  text-align: center;
  line-height: 180px;
  color: #8d939f;
  font-size: 48px;
  font-weight: 500;
  background-color: #cdd0d5;
  position: absolute;
  top: 0;
}
.sign-title {
  font-size: 32px;
  font-weight: 500;
  margin: 24px 0;
}
</style>
