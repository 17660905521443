<template>
  <div>
    <vueSignature
      ref="signature"
      :sigOption="{
        penColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgb(200,200,200)',
      }"
      :w="'100vw'"
      :h="'90vh'"
      :defaultUrl="dataUrl"
    />
    <van-button
      style="width: 30vw; margin: 0 1.5vw"
      type="default"
      @click="close"
    >
      取消
    </van-button>
    <van-button
      style="width: 30vw; margin: 0 1.5vw"
      type="warning"
      @click="clear"
      >清除
    </van-button>
    <van-button
      style="width: 30vw; margin: 0 1.5vw"
      type="primary"
      @click="save"
    >
      确定
    </van-button>
  </div>
</template>

<script>
export default {
  name: "SignView",
  data() {
    return {
      dataUrl: "",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    clear() {
      this.$refs.signature.clear();
    },
    save() {
      if (this.$refs.signature.isEmpty()) {
        this.$toast("请签名");
      } else {
        const png = this.$refs.signature.save();
        this.$emit("success", png);
        this.clear();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
